<template>
  <div>
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          <el-form  :inline="true">   
            <el-form-item label="工程编号/名称:">  <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.searchText"
          ></el-input></el-form-item>         
          
        
          <span style="padding-left:20px;"></span>
          <el-form-item label="安装施工队："> 
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.install"
          ></el-input>
          </el-form-item>
          <span style="padding-left:20px;"></span>
          <el-form-item label="现场管理员："> 
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.siteManager"
          ></el-input>
          </el-form-item>
<el-form-item label="验收人：" style="padding:0px">
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.acceptUser"
          ></el-input></el-form-item>
         <span style="padding-left:20px;"> </span>
         <el-form-item label="试验结果："> 
          <el-select
            v-model="searchBox.result"
            clearable
            placeholder="请选择"
            size="small"
            multiple
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          </el-form-item>
           <el-form-item label="验收时间:" style="padding:0px"> <el-date-picker
              size="small"
              v-model="searchBox.time"
              type="datetimerange"
              style="width: 400px"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="选择验收时间"
            >
            </el-date-picker></el-form-item>
          
           
            <span style="margin-right:20px"></span>
             <el-form-item label="" style="padding:0px" label-width="0px">
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="search"
            >查询</el-button
          >
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="resetData"
            >重置</el-button
          >
          <el-button class="SearchBtn" size="small" @click="deriveExcel"
            >导出</el-button
          >
          <!-- <el-button class="SearchBtn" size="small" @click="deriveacComplish" 
              >验收完成</el-button
            > -->
            </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <div style="display: flex">
         
            <el-button class="SearchBtn" size="small" @click="deriveacComplish"
              >验收完成</el-button
            >
          </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 10px 0"
            border
            height="calc(100vh - 415px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
            @sort-change="sort_change"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
              :index="(searchBox.current - 1) * searchBox.size + 1"
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="install"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="address"
              label="地址"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="standPipe"
              label="上游立管"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="contractCode"
              label="表具信息"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div
                  style="cursor: pointer; color: #409eff"
                  @click="chartDetil(row)"
                >
                  查看
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="graderNumber"
              label="表刚号"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                  {{row.workCityAcceptList && row.workCityAcceptList.length>0 && row.workCityAcceptList[0].graderNumber}}
               </template>
            </el-table-column>
            <el-table-column
              prop="titles"
              label="表封号"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                  {{row.workCityAcceptList && row.workCityAcceptList.length>0 && row.workCityAcceptList[0].titles}}
               </template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="表类型"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                  {{row.workCityAcceptList && row.workCityAcceptList.length>0 && row.workCityAcceptList[0].type}}
               </template>
            </el-table-column> -->
            <el-table-column
              prop="contractCode"
              label="当前验收结果"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                  <el-tag  :type=" row.result == 1 ?'success':'danger'">{{
                    row.result == 1 ? "合格" : row.result == 2 ? "不合格" : "未验收"
                    }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="acceptUser"
              label="当前验收人"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                 <span v-if="row.workCityAcceptList&&row.workCityAcceptList.length>0">{{row.workCityAcceptList[0].acceptUser}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="acceptDate"
              label="当前验收日期" width="150px"
              align="center"
              show-overflow-tooltip
              sortable="custom"
            >
             <template slot-scope="{ row }">
                 <span v-if="row.workCityAcceptList&&row.workCityAcceptList.length>0">{{row.workCityAcceptList[0].acceptDate}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="left" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button
                  type="primary"             
                  size="small"
                  @click="seeDetil(row)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="历次验收信息"
      :visible.sync="detilDialog"
      width="45%"
      :close-on-click-modal="false"
    >
      <el-form ref="project" :model="project" size="mini">
        <el-row>
          <el-col :span="12">
            <el-form-item label="地址：" prop="positionCode">
              {{ form.address }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上游立管：" prop="positionCode">
              {{ form.standPipe }}
            </el-form-item>
          </el-col>
        </el-row>
        <!--数据表格-->
        <el-table
          ref="tableData"
          :data="tableDataDesc"
          style="width: 100%; margin: 15px 0"
          border
          :stripe="true"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
        >
          <el-table-column
            prop="acceptDate"
            label="验收日期"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="result"
            label="验收结果"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">{{
              row.result == 1 ? "合格" : row.result == 2 ? "不合格" : "未验收"
            }}</template>
          </el-table-column>
          <el-table-column
            prop="acceptUser"
            label="验收人"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </el-form>
    </el-dialog>
    <el-dialog
      title="导出"
      :visible.sync="deriveDialog"
      width="25%"
      :close-on-click-modal="false"
      @close="clearAll"
    >
      <el-form ref="project" :model="project" size="mini" :rules="rule">
        <el-row>
          <el-col :span="24">
            <el-form-item label="工程名称：" prop="workId">
              <el-select
                v-model="project.workId"
                value-key="projectId"
                clearable
                placeholder="请选择"
                size="small"
                @change="gatProName"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="验收结果" prop="state">
              <el-checkbox-group v-model="project.state">
                <el-checkbox label="1">合格</el-checkbox>
                <el-checkbox label="2">不合格</el-checkbox>
                <el-checkbox label="3">未验收</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button
          type="primary"
          class="SearchBtn"
          size="small"
          @click="excelCity"
          >导出</el-button
        >
        <el-row v-if="showMsg">
          <el-col :span="24">
            <el-form-item label="本次导出汇总：" prop="positionCode">
              <div style="color: red">
                共导出{{getTotal(project.state,objData)}}条
                <span v-if="project.state.findIndex(el => el == 1) >= 0">，合格{{ objData.cityQualified || 0}}条</span>
                <span v-if="project.state.findIndex(el => el == 2) >= 0">，不合格{{ objData.cityNoQualified || 0 }}条</span>
                <span v-if="project.state.findIndex(el => el == 3) >= 0">，未验收{{  objData.noAccept || 0}}条</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      title="集体户验收完成"
      :visible.sync="accomplishDialog"
      width="500px"
      :close-on-click-modal="false"
      @close="clearAll"
    >
      <el-form ref="project1" :model="project" size="mini" :rules="rules1">
        <el-row>
          <el-col :span="17">
            <el-form-item label="工程名称：" prop="workId">
              <el-select
                v-model="project.workId"
                clearable filterable 
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="getProjectStatus()"
              >获取验收情况</el-button
            >
          </el-col>
        </el-row>

        <el-row v-if="JSON.stringify(objData.pipeTotal)">
          <el-col :span="16">
            <el-form-item label="验收情况说明：" prop="positionCode">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="JSON.stringify(objData.pipeTotal)">
          <el-col :span="24">
            <el-form-item label="验收立管情况：" prop="positionCode">
              总数量 {{ objData.pipeTotal }}，合格数量{{
                objData.pipeQualified
              }}，不合格数量{{ objData.pipeNoQualified }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="JSON.stringify(objData.cityTotal)">
          <el-col :span="24">
            <el-form-item label="验收集体户情况：" prop="positionCode">
              总数量 {{ objData.cityTotal }}，合格数量{{
                objData.cityQualified
              }}，不合格数量{{ objData.cityNoQualified }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          v-if="
            JSON.stringify(objData.pipeTotal) &&
            (!objData.cityTotal ||
              !objData.pipeTotal ||
              objData.pipeTotal != objData.pipeQualified ||
              objData.cityTotal != objData.cityQualified)
          "
        >
          <el-col :span="16">
            <div style="color: red">当前验收情况，未达到验收完成标准</div>
          </el-col>
        </el-row>
        <el-button
          v-if="
            objData.pipeTotal &&
            objData.cityTotal &&
            objData.pipeTotal == objData.pipeQualified &&
            objData.cityTotal == objData.cityQualified
          "
          type="primary"
          class="SearchBtn"
          size="small"
          @click="editstatus()"
          >验收完成</el-button
        >
        <el-button
          v-else
          class="SearchBtn"
          size="small"
          @click="accomplishDialog = false"
          >关闭</el-button
        >
      </el-form>
    </el-dialog>
     <!-- 表具信息 -->
    <el-dialog
      title="表具信息"
      :visible.sync="meterDialog"
      width="45%"
      :close-on-click-modal="false"
    >
      <el-form ref="meter" :model="meter" size="mini">
        <el-row>
          <el-col :span="8">
            <el-form-item label="表编号：" prop="positionCode">
              {{ meter.gradeNumber }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="燃气表类型：" prop="positionCode">
              {{ meter.typeName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="表向：" prop="positionCode">
              {{ meter.directionName }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="燃气表厂家：" prop="positionCode">
              {{ meter.venderName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item label="表封号：" prop="positionCode">
                {{ meter.titles }}
              </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
              <el-form-item label="编号：" prop="positionCode">
                {{ meter.number }}
              </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  indoor_getCity,
  prepare_getWorkProject,
  indoor_excelCity,
  indoor_getAccept,
  indoor_finishAccept,
  indoor_getExcels,
} from "../../RequestPort/intranet/index";
import getNowFormatDate from "./../../common/js/nowDate.js";
import { exportMethod } from "./../../common/js/exportExcel.js";
export default {
  name: "lockout",
  data() {
    return {
      status: "12",
      activeName: "1",
      projectList: [],
      tableDataDesc: [],
      from: [],
      formDesc: {},
      meter:{},
      meterDialog:false,
      project: {
        state: [],
      },
      objData: {},
      searchBox: {
        current: 1,
        size: 100,
        name: "",
        result: [],
        time:[]
      },
      total: 0,
      detilDialog: false,
      deriveDialog: false,
      accomplishDialog: false,
      consequence: true,
      typeList: [
        {
          label: "合格",
          value: 1,
        },
        {
          label: "不合格",
          value: 2,
        },
        {
          label: "未验收",
          value: 3,
        },
      ],
      tableData: [],
      projectName: "",
      showMsg: 0,
      form: {},
      rule: {
        workId: {
          required: true,
          message: "请选择工程名称",
          trigger: "change",
        },
        state: {
          required: true,
          message: "请选择验收结果",
          trigger: "change",
        },
      },
      rules1:{
         workId: {
          required: true,
          message: "请选择工程名称",
          trigger: "change",
        },
      },
    }
  },
  mounted() {
    // console.log(this.$refs.SearchBar.offsetHeight);
    this.loadList(this.searchBox);

    this.getOption();
  },
  methods: {
    sort_change(column){
      if(column.order=='ascending'){
        this.searchBox.sortType = 'asc';
      }else{
        this.searchBox.sortType = 'desc';
      }
      this.loadList(this.searchBox);
    },
    chartDetil(row) {
      this.meterDialog = !this.chartDialog;
      this.meter = row;
    },
    getTotal(state,objData){
      let total=0
      if(state.findIndex(el => el == 1) >= 0){
        total+=objData.cityQualified 
      }
      if(state.findIndex(el => el == 2) >= 0){
        total+=objData.cityNoQualified 
      }
      if(state.findIndex(el => el == 3) >= 0){
        total+=objData.noAccept 
      }
      return total      
    },
    getOption() {
      // 8  城区集体户内验收
      prepare_getWorkProject({ flag: 2 }).then((res) => {
        this.projectList = res.data;
      });
    },
    deriveacComplish() {
      this.accomplishDialog = !this.accomplishDialog;
    },
    deriveExcel() {
      console.log("导出");
      if(this.$refs.project){
        this.$refs.project.clearValidate();
      }
      this.showMsg = ''
      this.deriveDialog = !this.deriveDialog;
    },
    seeDetil(row) {
      this.form = row;
      if (row.workCityAcceptList) {
        this.tableDataDesc = row.workCityAcceptList;
      } else {
        this.tableDataDesc = [];
      }

      this.detilDialog = true;
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList();
    },

    search() {
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    loadList(obj) {
      if (obj) {
        this.searchBox.current = 1;
      }
      if(this.searchBox.time&&this.searchBox.time.length>0){
        this.searchBox.startDate = this.searchBox.time[0]
        this.searchBox.endDate = this.searchBox.time[1]
      }else{
         this.searchBox.startDate = ''
        this.searchBox.endDate = ''
      }
      indoor_getCity(this.searchBox).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 重置
    resetData() {
      this.searchBox = {
        current: 1,
        size: 100,
        result: [],
        time:[]
      };
      this.loadList(this.searchBox);
    },
    // 取出工程名称
    gatProName(val) {
      this.projectName = this.projectList.find(el => el.id == val).projectName
    },
    excelCity() {
      this.$refs["project"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          indoor_excelCity(this.project).then((res) => {
            loading.close();
            this.project.flag = 2;
            indoor_getExcels(this.project).then((res) => {
              this.objData = res.data;
              this.showMsg = 1;
            });
            if (res) {
              const fileName =
                this.projectName + "_城区集体户内验收_" + getNowFormatDate();
              exportMethod(res, fileName);
              // this.clearAll()
            }
          });
        }
      });
    },
    clearAll() {
      this.project = {
        workId: "",
        flag: "",
        state: [],
      };
      this.objData = {};
    },
    getProjectStatus() {
      this.$refs["project1"].validate((valid) => {
        if (valid) {
          indoor_getAccept({ workId: this.project.workId, flag: 2 }).then((res) => {
            if (res.code == 200) {
              this.objData = res.data;
            }
          });
            }
        })
    },
    editstatus() {
      indoor_finishAccept({ workId: this.project.workId, flag: 2 }).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("验收完成");
            this.loadList(1);
            this.accomplishDialog = false
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
</style>